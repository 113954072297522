import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import Navigation from './Navigation';
import SubNavigation from './SubNavigation';
import navIcon from '../assets/img/nav-icon.png';

const HeaderContainer = styled.div`
  padding: ${props => props.theme.layout.margin.small} 0 0 0;
  height: ${props => props.theme.layout.headerHeight};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Logo = styled.h1`
  text-align: right;
  position: fixed;
  z-index: 100;
  padding: 10px 20px;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  margin-bottom: 3.5rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: lighter;

  strong {
    font-weight: bold;
  }

  @media only screen and (min-width: ${props => props.theme.bp.small}) {
    position: relative;
    padding: 0;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: inherit;
    }
  }
`;

const NavigationContainer = styled.div``;

const NavButton = styled.button`
  width: 25px;
  height: 25px;
  border: none;
  background-color: transparent;
  float: left;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media only screen and (min-width: ${props => props.theme.bp.small}) {
    display: none;
  }

  img {
    width: 100%;
  }
`;

const StyledNavigationContainer = styled(NavigationContainer)`
  display: none;

  @media only screen and (min-width: ${props => props.theme.bp.small}) {
    display: block;
  }
`;

const NavItem = styled.li`

  ${props => props.indent && css`
    margin-left: 10px;
  `}  
`;

const MobileNav = styled.nav`
  background-color: white;
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 80px 20px 20px 20px;
  background-color: rgba(255, 255, 255, 0.9);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
    li {
      margin-bottom: 10px;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`;

class Header extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { showNav: false };
  }

  mobileToggleHandler = () => {
    this.setState(state => ({ showNav: !state.showNav }));
  }
  
  render() {

    const mobileNav = (
      <MobileNav onClick={this.mobileToggleHandler}>
        <ul>
          <NavItem><NavLink to="/neuigkeiten">Aktuelles</NavLink></NavItem>
          <NavItem><NavLink to="/projekte">Projekte</NavLink></NavItem>
          <NavItem><NavLink to="/wettbewerbe">Wettbewerbe</NavLink></NavItem>
          <NavItem><NavLink to="/buero">Büro</NavLink></NavItem>
          <NavItem indent><NavLink to="/buero/bueropartner">Büropartner</NavLink></NavItem>
          <NavItem indent><NavLink to="/buero/team">Team</NavLink></NavItem>
          {/* <NavItem indent><NavLink to="/buero/haltung">Haltung</NavLink></NavItem> */}
          <NavItem indent><NavLink to="/buero/stellenangebote">Stellenangebote</NavLink></NavItem>
          <NavItem><NavLink to="/veroeffentlichungen">Veröffentlichungen</NavLink></NavItem>
          <NavItem indent><NavLink to="/veroeffentlichungen/publikationen">Publikationen</NavLink></NavItem>
          <NavItem indent><NavLink to="/veroeffentlichungen/presse">Presse</NavLink></NavItem>
          <NavItem><NavLink to="/kontakt">Kontakt</NavLink></NavItem>
          <NavItem><NavLink to="/kontakt/impressum">Impressum</NavLink></NavItem>
          <NavItem><NavLink to="/kontakt/datenschutz">Datenschutz</NavLink></NavItem>
        </ul>
      </MobileNav>
    );

    return (
      <HeaderContainer> 
        <Logo>
          <NavButton onClick={this.mobileToggleHandler}><img src={navIcon} alt="open navigation" /></NavButton>
          <NavLink to="/">Robert Meyer und Tobias Karlhuber <strong>Architekten</strong></NavLink>
        </Logo>
        <StyledNavigationContainer>
          <Navigation />
          <SubNavigation />
        </StyledNavigationContainer>

        {this.state.showNav ? <h1>{mobileNav}</h1> : null}
      </HeaderContainer>
    );
  }
}

export default Header;