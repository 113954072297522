import React from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import FooterNavigation from './FooterNavigation';

const PageContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-bottom: 100px;

  ${props => props.margin && css`
    margin-top: 5.5rem; 
  `}
`;

const Page = props => {
  return (
    <PageContainer {...props}>
      <Helmet><title>Robert Meyer und Tobias Karlhuber Architekten  - {props.title}</title></Helmet>
      {props.children}
      <footer>
        <FooterNavigation />
      </footer>
    </PageContainer>
  );
}

export default Page;