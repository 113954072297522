import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import AutoFitImage from 'react-image-autofit-frame';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';
import pdfIcon from '../assets/img/icon_pdf.png';

// STYLES
const StyledSlider = styled(Slider)`
  height: auto;
  overflow: hidden;

  @media only screen and (min-width: ${props => props.theme.bp.medium}) {
    height: 650px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    height: 760px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.xlarge}) {
    height: 1000px;
  }
`;

const Teaser = styled.h2`
  font-weight: normal;
`;

const Description = styled.div``;

const Table = styled.table`
  display: table;
`;

const Row = styled.div`
  display: table-row;
`;

const Col = styled.div`
  display: table-cell;

  &:first-child {
    padding-right: 10px;
  }

  p {
    margin-bottom: 0;
  }
`;

const Headline = styled.h2`
  margin: 0;
`;

const StyledAutoFitImage = styled(AutoFitImage)`
  margin: 5px;
`;

const ThumbList = styled.div`
  padding: 10px 10px 0 0;
`;

const ThumbSlider = styled(Slider)`
  overflow: hidden;
  height: 90px;
  border-bottom: 1px dotted ${props => props.theme.color.greyMedium};
  margin-bottom: ${props => props.theme.layout.margin.small}; 

  @media only screen and (min-width: ${props => props.theme.bp.medium}) {
    height: 70px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    height: 90px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.xlarge}) {
    height: 110px;
  }
`;

// COMPONENT
class Detail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      frameHeight: null,
      thumbHeight: null
    };
    this.pageRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      frameHeight: this.pageRef.current.clientWidth / 1.46,
      thumbHeight: '70px'
    });
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  resizeHandler = () => {
    if (window.innerWidth >= 2560) {
      this.setState({
        frameHeight: '1000px',
        thumbHeight: '90px'
      });
    } else if (window.innerWidth >= 1921) {
      this.setState({
        frameHeight: '760px',
        thumbHeight: '70px'
      });
    } else if (window.innerWidth >= 952) {
      this.setState({
        frameHeight: '650px',
        thumbHeight: '50px'
      });
    } else {
      this.setState({
        frameHeight: this.pageRef.current.clientWidth / 1.46,
        thumbHeight: '70px'
      });
    }
  };

  render() {
    const currentItem = this.props.data.filter(item => item.title_slug === this.props.match.params.slug)[0];

    const volume = currentItem.volume ? <Row><Col>Volumen:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.volume }}></Col></Row> : null;
    const costs = currentItem.costs ? <Row><Col>Kosten:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.costs }}></Col></Row> : null;
    const builder = currentItem.builder ? <Row><Col>Bauherr:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.builder }}></Col></Row> : null;
    const client = currentItem.client ? <Row><Col>Auftraggeber:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.client }}></Col></Row> : null;
    const location = currentItem.location ? <Row><Col>Ort:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.location }}></Col></Row> : null;
    const commissioning = currentItem.commissioning ? <Row><Col>Beauftragung:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.commissioning }}></Col></Row> : null;
    const period = currentItem.period ? <Row><Col>Zeitraum:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.period }}></Col></Row> : null;
    const cooperation = currentItem.cooperation ? <Row><Col>Mitarbeiter:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.cooperation }}></Col></Row> : null;
    const partner = currentItem.partner ? <Row><Col>Partner:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.partner }}></Col></Row> : null;
    const photos = currentItem.photos ? <Row><Col>Fotos:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.photos }}></Col></Row> : null;
    const promoter = currentItem.promoter ? <Row><Col>Auslober:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.promoter }}></Col></Row> : null;
    const publications = currentItem.publications ? <Row><Col>Veröffentlichungen:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.publications }}></Col></Row> : null;
    const investor = currentItem.investor ? <Row><Col>Investor:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.investor }}></Col></Row> : null;
    const art = currentItem.art ? <Row><Col>Kunst am Bau:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.art }}></Col></Row> : null;
    const visualisation = currentItem.visualisation ? <Row><Col>Visualisierung:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.visualisation }}></Col></Row> : null;
    const organizer = currentItem.organizer ? <Row><Col>Veranstalter:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.organizer }}></Col></Row> : null;
    const status = currentItem.status ? <Row><Col>Projektstand:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.status }}></Col></Row> : null;
    const completion = currentItem.completion ? <Row><Col>Fertigstellung:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.completion }}></Col></Row> : null;
    const landscape_architect = currentItem.landscape_architect ? <Row><Col>Landschaftsarchitekt:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.landscape_architect }}></Col></Row> : null;
    const professional_planner = currentItem.professional_planner ? <Row><Col>Fachplaner:</Col><Col dangerouslySetInnerHTML={{ __html: currentItem.professional_planner }}></Col></Row> : null;
    const pdf = currentItem.pdf ? <a href={`${baseUrl}/${currentItem.pdf}`} target="_blank" rel="noopener noreferrer"><img src={pdfIcon} alt="PDF Link" /></a> : null;

    console.log(currentItem);
    if (currentItem.additional_data) {
      console.log(currentItem.additional_data);
    }

    const slider2Settings = {
      asNavFor: this.state.nav1,
      slidesToShow: 10,
      swipeToSlide: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 952,
          settings: {
            slidesToShow: 6
          }
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 4
          }
        }
      ]
    };

    return (
      <Page title="..." margin>
        <div ref={this.pageRef}>
          <StyledSlider
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
            speed={500}
            autoplaySpeed={5000}
          >
            {currentItem.gallery.map(image => (
              <AutoFitImage imgSize="contain" positionX="left" positionY="center" frameWidth="100%" frameHeight={this.state.frameHeight} imgSrc={`${baseUrl}/${image.path}`} />
            ))}
          </StyledSlider>
        </div>
          
        <ThumbSlider ref={slider => (this.slider2 = slider)} {...slider2Settings}>
          {currentItem.gallery.map((image, index) => (
            <ThumbList style={{ padding: '5px'}}>
              <StyledAutoFitImage imgSize="cover" positionX="center" positionY="center" frameWidth="" frameHeight={this.state.thumbHeight} imgSrc={`${baseUrl}/${image.path}`} style={{ margin: '5px'}} />
            </ThumbList>
          ))}
        </ThumbSlider>
        
        <Headline>{currentItem.title}</Headline>
        <Teaser dangerouslySetInnerHTML={{ __html: currentItem.teaser }}></Teaser>
        <Description dangerouslySetInnerHTML={{ __html: currentItem.description }}></Description>
{/* 
        <h3>Daten</h3>
        <Table>
          {client}
          {builder}
          {location}
          {commissioning}
          {period}
          {volume}
          {costs}
          {cooperation}
          {partner}
          {promoter}
          {investor}
          {photos}
          {publications}
          {art}
          {visualisation}
          {organizer}
          {status}
          {completion}
          {landscape_architect}
          {professional_planner}
        </Table>

        {pdf} */}
        
      </Page>
    );
  }
}

export default Detail;