import React from 'react';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';
import Overview from '../components/Overview';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import ProjectTeaser from '../components/ProjectTeaser';
import styled from 'styled-components';

const MasonryContainer = styled.div`
  margin: 0 -1.5rem;
  margin-top: -15px;
`;

const Publications = props => {

  console.log(props.data);
  return (
    <Page title="Publikationen" margin>
      {/* <Overview items={props.data} fallbackImage={`${baseUrl}/storage/uploads/team/team_placeholder.gif`} link="/veroeffentlichungen/publikationen" /> */}
      <MasonryContainer>
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 600: 2, 900: 3}}>
          <Masonry gutter={15}>
            {props.data.map(pub => (
              <ProjectTeaser img={pub.image.path} alt={pub.title} link={`/veroeffentlichungen/publikationen/${pub.title_slug}`}>
                <div dangerouslySetInnerHTML={{ __html: pub.title }}></div>
              </ProjectTeaser>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </MasonryContainer>
    </Page>
  );
}

export default Publications;