import React from 'react';
import Page from '../components/Page';
import Media from '../components/Media';
import { withRouter } from 'react-router-dom';
import Overview from '../components/Overview';

const Releases = props => {

  console.log(props);
  const category = props.location.pathname.split('/')[2];

  const categoryMap = {
    presse: 'press',
    publikationen: 'publication',
    ausstellungen: 'exhibition'
  }
  const releases = props.data.filter(data => data.type === categoryMap[category])

  return (
    <Page title="Veröffentlichungen" margin>
      {/* { releases.map(release => (
        <Media image={release.poster} title={release.title} link={`/veroeffentlichungen/${category}/${release.title_slug}`}>
          {release.teaser}
        </Media>
      )) } */}
    </Page>
  );
}

export default withRouter(Releases);