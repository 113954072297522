import React from 'react';
import styled from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';

const Nav = styled.nav`
  text-align: left;
  padding-top: ${props => props.theme.layout.margin.small};
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  display: inline-block;
  margin-right: 15px;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  text-transform: uppercase;
  color: ${props => props.theme.color.greyMedium};

  &:hover,
  &.active {
    text-decoration: inherit;
    color: black;
  }
`;

const SubNavigation = props => {

  const page = props.location.pathname.split('/')[1];
  let subNav = null;

  switch (page) {
    case 'buero':
      subNav = (
        <Nav {...props}>
          <NavList>
            {/* <NavItem>
              <StyledNavLink to="/buero/partner">Partner</StyledNavLink>
            </NavItem> */}
            {/* <NavItem>
              <StyledNavLink to="/buero/neuigkeiten">Aktuelles</StyledNavLink>
            </NavItem> */}
            <NavItem>
              <StyledNavLink to="/buero/bueropartner">Büropartner</StyledNavLink>
            </NavItem>
            <NavItem>
              <StyledNavLink to="/buero/team">Team</StyledNavLink>
            </NavItem>
            <NavItem>
              <StyledNavLink to="/buero/ueber-uns">Über uns</StyledNavLink>
            </NavItem>
            <NavItem>
              <StyledNavLink to="/buero/stellenangebote">Stellenangebote</StyledNavLink>
            </NavItem>
          </NavList>
        </Nav>
      );
      break;
    case 'veroeffentlichungen':
      subNav = (
        <Nav>
          <NavList>
            <NavItem>
              <StyledNavLink to="/veroeffentlichungen/publikationen">Publikationen</StyledNavLink>
            </NavItem>
            <NavItem>
              <StyledNavLink to="/veroeffentlichungen/presse">Presse</StyledNavLink>
            </NavItem>
            <NavItem>
              <StyledNavLink to="/veroeffentlichungen/pressekontakt">Pressekontakt</StyledNavLink>
            </NavItem>
            {/* <NavItem>
              <StyledNavLink to="/veroeffentlichungen/ausstellungen">Ausstellungen</StyledNavLink>
            </NavItem> */}
          </NavList>
        </Nav>
      );
      break;
    // case 'kontakt':
    //   subNav = (
    //     <Nav>
    //       <NavList>
    //         <NavItem>
    //           <StyledNavLink to="/kontakt/impressum">Impressum</StyledNavLink>
    //         </NavItem>
    //         <NavItem>
    //           <StyledNavLink to="/kontakt/datenschutz">Datenschutz</StyledNavLink>
    //         </NavItem>
    //       </NavList>
    //     </Nav>
    //   );
    //   break;
    default:
        subNav = (
          <Nav>
            <NavList>
              <NavItem>&nbsp;</NavItem>
            </NavList>
          </Nav>
        );
      break;
  }
  
  return subNav;
}

export default withRouter(SubNavigation);