import React from 'react';
import Page from '../components/Page';

const Imprint = props => {

  return (
    <Page title="Impressum" margin>
      <h2>{ props.data.title || null }</h2>
      <div dangerouslySetInnerHTML={{ __html: props.data.text || null }}></div>
    </Page>
  );
}

export default Imprint;