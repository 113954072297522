import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import baseUrl from '../utils/baseUrl';
import { Link } from 'react-router-dom';

const Image = styled.img`
  width: 100%;
`;

const StyledCol = styled(Col)`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const Overview = props => {

  return (
    <Row>
      {props.items.map(item => {
        let link = null;
        
        if (item.title && props.link) {
          link = item.title_slug;
        } else if (item.name && props.link) {
          link = item.name_slug;
        };

        return (
          <StyledCol xs={12} sm={6} md={4}>
            <Row>
              <Col xs={12} sm={6}>
                {link ? <StyledLink to={`${props.link}/${link}`}><Image src={item.image.path ? `${baseUrl}/${item.image.path}` : props.fallbackImage} alt="" /></StyledLink> : <Image src={item.image.path ? `${baseUrl}/${item.image.path}` : props.fallbackImage} alt="" /> }
              </Col>
              <Col xs={12} sm={6}>
                {link ? <StyledLink to={`${props.link}/${link}`}>{item.title || item.name}</StyledLink> : (item.title || item.name) }
                {item.job_title ? <p>{ item.job_title }</p> : null }
              </Col>
            </Row>
          </StyledCol>
        );
      })}
    </Row>
  );
};

export default Overview;