import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import baseUrl from '../utils/baseUrl';

const StyledNavLink = styled(NavLink)`
  position: relative;
  display: inline-block;
  width: 100%;

  &:hover {
    div {
      opacity: 0.8;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  transition: all linear ${props => props.theme.fx.speed};
`;

const ProjectTeaser = props => {
  return (
    <StyledNavLink to={props.link}>
      <img className="fluid" src={`${baseUrl}/${props.img}`} alt={props.alt} />
      <Overlay>{props.children}</Overlay>
    </StyledNavLink>
  );
}

export default ProjectTeaser;