import React from 'react';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import styled from 'styled-components';
import Page from '../components/Page';
import ProjectTeaser from '../components/ProjectTeaser';

const MasonryContainer = styled.div`
  margin: 0 -1.5rem;
`;

const Contests = props => {
  
  return (
    <Page title="Wettbewerbe">
      <MasonryContainer>
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 600: 2, 900: 3}}>
          <Masonry gutter={15}>
            {/* {props.data.map(project => (
              <ProjectTeaser img={project.gallery[0].path} alt={project.title} link={`/wettbewerbe/${project.title_slug}`}>
                <div dangerouslySetInnerHTML={{ __html: project.title }}></div>
              </ProjectTeaser>
            ))} */}

            {props.data.map(project => (
              <ProjectTeaser img={project.poster.styles ? project.poster.styles[0].path : project.poster.path} link={`/wettbewerbe/${project.title_slug}`}>
                <div dangerouslySetInnerHTML={{ __html: project.title }}></div>
              </ProjectTeaser>

            ))}
          </Masonry>
        </ResponsiveMasonry>
      </MasonryContainer>
    </Page>
  );
}

export default Contests;