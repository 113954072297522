import React from 'react';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';
import Overview from '../components/Overview';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PressItem = styled.div`
  margin-bottom: 3.5rem;
`;

const PressHeadline = styled.h3`
  margin: 0;
`;

const PressImage = styled.img`
  max-width: 200px;
`;

const Press = props => {

  console.log(props.press);
  return (
    <Page title="Presse" margin>
      {props.press.map(item => (
        <PressItem>
          { item.gallery.length > 0 ? <PressHeadline><Link to={`/veroeffentlichungen/presse/${item.title_slug}`}>{item.title}</Link></PressHeadline> : <PressHeadline>{item.title}</PressHeadline> }
          <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
          { item.image ? <PressImage src={`${baseUrl}/${item.image.path}`} alt={item.title} /> : null }
        </PressItem>
      ))}
      {/* <Overview items={props.press} fallbackImage={`${baseUrl}/storage/uploads/team/team_placeholder.gif`} link="/veroeffentlichungen/presse" /> */}
    </Page>
  );
}

export default Press;