import React from 'react';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';
import Overview from '../components/Overview';


const Employees = props => {

  const headTeam = props.team.filter(member => member.is_head);
  const employeeTeam = props.team.filter(member => !member.is_head);

  return (
    <Page title="Mitarbeiter" margin>
      <Overview items={headTeam} />
      <Overview items={employeeTeam} fallbackImage={`${baseUrl}/storage/uploads/team/team_placeholder.gif`} />
    </Page>
  );
}

export default Employees;