import React from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';

const LayoutCSS = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html, body {
    font-size: 62.5%;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  body {
    margin: 0;
    font-weight: lighter;
    line-height: 1.5;
    text-align: left;
  }

  [tabindex="-1"]:focus {
    outline: none !important;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  dfn {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  a {
    color: theme-color("primary");
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }

  figure {
    margin: 0 0 1rem;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  a,
  area,
  button,
  [role="button"],
  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: inherit;
    text-align: left;
    caption-side: bottom;
  }

  th {
    text-align: inherit;
  }

  label {
    display: inline-block;
    margin-bottom: .5rem;
  }

  button {
    border-radius: 0;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }

  body {
    font-family: ${props => props.theme.font.default};
    color: ${props => props.theme.color.greyDark};
    font-size: ${props => props.theme.fontSize.default};
  }

  main {
    position: relative;
  }

  h1 {
    font-size: ${props => props.theme.fontSize.huge};
    font-weight: normal;
  }

  h2 {
    font-size: ${props => props.theme.fontSize.large};
    font-weight: bold;
    margin: 0 0 ${props => props.theme.layout.margin.default} 0;
  }

  h3 {
    font-size: ${props => props.theme.fontSize.large};
    font-weight: bold;
    margin: 15px 0 10px 0;

    span {
      font-weight: normal;
    }
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 0.8rem;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }

  img.fluid {
    width: 100%;
  }

  .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity ${props => props.theme.fx.speed} linear;
  }

  .fade-exit {
    /* opacity: 1; */
    opacity: 0;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity ${props => props.theme.fx.speed} linear;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .fadeIn {
    animation-name: fadeIn;
    animation-duration: ${props => props.theme.fx.speed};
  }

  .clearfix::before,
  .clearfix::after {
    content: " ";
    display: table;
  } 

  .clearfix::after {
    clear: both;
  }

  .clearfix {
    *zoom: 1; 
  }
`;

const LayoutContainer = styled.div`
  max-width: ${props => props.theme.layout.maxWidth};
  margin: 0 auto;
  padding: 0 ${props => props.theme.layout.padding};

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    max-width: ${props => props.theme.layout.maxWidthLarge};
  }

  @media only screen and (min-width: ${props => props.theme.bp.xlarge}) {
    max-width: ${props => props.theme.layout.maxWidthXLarge};
  }
`;

const theme = {
  layout: {
    maxWidth: '992px',
    maxWidthLarge: '1150px',
    maxWidthXLarge: '1500px',
    headerHeight: '100px',
    headerHeightLarge: '150px',
    padding: '20px',
    margin: {
      small: '10px',
      default: '20px',
      large: '40px',
      section: ''
    }
  },
  fontSize: {
    default: '1.2rem',
    large: '1.6rem',
    huge: '1.8rem'
  },
  color: {
    greyDark: '#444444',
    greyMedium: '#666666'
  },
  font: {
    default: 'Arial, sans-serif'
  },
  fx: {
    speed: '0.3s'
  },
  bp: {
    small: '650px',
    medium: '952px',
    large: '1921px',
    xlarge: '2560px'
  },
  flexboxgrid: {
    // gridSize: 12,
    // gutterWidth: 3,
    // outerMargin: 0,
    // mediaQuery: 'only screen',
    breakpoints: {
      xs: 0,
      sm: 40, 
      md: 62,
      lg: 90
    }
  }
};

const Layout = props => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <LayoutCSS />
      <LayoutContainer className="fadeIn">{props.children}</LayoutContainer>
    </React.Fragment>
  </ThemeProvider>
);

export default Layout;