import React from 'react';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Link } from 'react-router-dom';

const NewsItem = styled.div`
  margin-bottom: 3.5rem;
  position: relative;

  &:hover {
    img {
      display: block;
    }
  }
`;

const NewsDate = styled.p`
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: 1rem;
  
  @media screen and (min-width: ${props => props.theme.bp.medium}) {
    // max-width: 60%;
  }
`;

const Text = styled.div`
  
  @media screen and (min-width: ${props => props.theme.bp.medium}) {
    // max-width: 60%;
  }
`;

const NewsImage = styled.img`
  max-width: 100%;
  
  @media screen and (min-width: ${props => props.theme.bp.medium}) {
    display: none;
    //max-width: 40%;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const StyledCol = styled(Col)`
  position: relative;
`;

const NewsContent = styled.div`
  @media screen and (min-width: 640px) {
    padding-right: 5rem;
  }
`;

const Neuigkeiten = props => {

  props.data.sort((a, b) => {
    var dmyA = a.date.split('.');
    var dmyB = b.date.split('.');
    var timeA = new Date(dmyA[2], dmyA[1] - 1, dmyA[0]).getTime();
    var timeB = new Date(dmyB[2], dmyB[1] - 1, dmyB[0]).getTime();

    if (timeA < timeB) {
      return 1;
    }
    if (timeA > timeB) {
      return -1;
    }
    return 0;
  });

  return (
    <Page title="Neuigkeiten" margin>
      {props.data.map(item => (
      <NewsItem>
        <Row>
          <Col xs={12} sm={6}>
            <NewsContent>
              <NewsDate>{ item.date }</NewsDate>
              <Title>{ item.title }</Title>
              <Text dangerouslySetInnerHTML={{ __html: item.text }}></Text>
            </NewsContent>
          </Col>
          <StyledCol xs={12} sm={6}>
            { item.image ? <NewsImage src={`${baseUrl}/${item.image.path}`} alt={item.title} /> : null }
          </StyledCol>
        </Row>
      </NewsItem>
      ))}
    </Page>
  );
  
}

export default Neuigkeiten;