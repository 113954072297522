import React from 'react';
import Page from '../components/Page';
import IFrame from 'react-iframe';
import styled from 'styled-components';
import map from '../assets/img/Karte_MKA.jpg';

const StyledIFrame = styled(IFrame)`
  border: none;
  width: 100%;
  margin-top: 2rem;
`;

const Map = styled.img`
  max-width: 100%;
`;

const Contact = props => {

  return (
    <Page title="Kontakt">
      <h2>{props.data.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: props.data.text }}></div>
      <a href="/Karte_MKA.pdf" target="_blank">
        <Map src={map} alt="Karte Meyer-Karlhuber-Architekten"></Map>
      </a>
      {/* <StyledIFrame 
        url="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2662.9552968804187!2d11.5729643207205!3d48.13038546414669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sKlenzestra%C3%9Fe%2038%20Klenzestra%C3%9Fe%2038%2C%2080469%20M%C3%BCnchen!5e0!3m2!1sde!2sde!4v1576506688662!5m2!1sde!2sde"
        width="540" height="400" /> */}
    </Page>
  );
}

export default Contact;