import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';

// STYLES
const Image = styled.img`
  width: 100%;
  margin-bottom: 1.5rem;
  margin-right: 20px;
  height: 100%;
  position: relative;
  display: block;
  
  @media screen and (min-width: 768px) {
    max-width: 150px;
    position: fixed;
    width: 150px;
    height: auto;
    display: none;
  }
`;

const Gruppenbild = styled.img`
  width: 100%;
  margin-bottom: 1.5rem;
  margin-right: 20px;
`;

const CVRow = styled.div`
  display: flex;
  margin-bottom: 1.2rem;

  h2 {
    font-size: 1.5rem;
    font-weight: normal;
    margin: 0;
  }

  &:last-child {
    margin-bottom: 5rem;
  }
`;

const CVColLabel = styled.div`
  flex: 0 0 90px;
`;

const CVColText = styled.div`
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 640px) {
    // flex-direction: row;
  }

  div {
    margin-bottom: 1.5rem;

    @media screen and (min-width: 640px) {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: normal;
    margin: 0 0 1rem 0;
  }
`;

const theme = {
  flexboxgrid: {
    gutterWidth: 5, // rem
  }
}


// COMPONENT
class Geschaeftsfuehrer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById('cv_meyer').addEventListener('mouseenter', this.showMouseHandler);
    document.getElementById('cv_meyer').addEventListener('mouseleave', this.hideMouseHandler);
    document.getElementById('cv_meyer').addEventListener('mousemove', this.followMouseHandler);
    document.getElementById('cv_karlhuber').addEventListener('mouseenter', this.showMouseHandler);
    document.getElementById('cv_karlhuber').addEventListener('mouseleave', this.hideMouseHandler);
    document.getElementById('cv_karlhuber').addEventListener('mousemove', this.followMouseHandler);
  }
  
  showMouseHandler(e) {
    let img = e.target.closest('.cv').querySelector('img');
    let computedStyles = window.getComputedStyle(img);
    if (computedStyles.position === 'fixed') {
      img.style.display = 'block';
    }
  }

  hideMouseHandler(e) {
    let img = e.target.closest('.cv').querySelector('img');
    let computedStyles = window.getComputedStyle(img);
    if (computedStyles.position === 'fixed') {
      img.style.display = 'none';
    }
  }
  
  followMouseHandler(e) {
    let img = e.target.closest('.cv').querySelector('img');
    let computedStyles = window.getComputedStyle(img);
    if (computedStyles.position === 'fixed') {
      img.style.top = e.clientY + 10 + 'px';
      img.style.left = e.clientX + 10 + 'px';
    }
  }

  componentWillUnmount() {
    document.getElementById('cv_meyer').removeEventListener('mouseenter', this.showMouseHandler);
    document.getElementById('cv_meyer').removeEventListener('mouseleave', this.hideMouseHandler);
    document.getElementById('cv_meyer').removeEventListener('mousemove', this.followMouseHandler);
    document.getElementById('cv_karlhuber').removeEventListener('mouseenter', this.showMouseHandler);
    document.getElementById('cv_karlhuber').removeEventListener('mouseleave', this.hideMouseHandler);
    document.getElementById('cv_karlhuber').removeEventListener('mousemove', this.followMouseHandler);
  }

  render() {
   
    const { data } = this.props;
    
    return (
      <Page title="Büropartner" margin>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <Row>
              <Col xs={12} sm={6}><Gruppenbild src={`${baseUrl}/${data.gruppenfoto_1.path}`} alt="" /></Col>
              <Col xs={12} sm={6}><Gruppenbild src={`${baseUrl}/${data.gruppenfoto_2.path}`} alt="" /></Col>
            </Row>


            <Row>
              <Col xs={12} sm={6} id="cv_meyer" className="cv">
                <Header>
                  <Image src={`${baseUrl}/${data.image_meyer.path}`} alt={data.name_meyer} id="image_meyer"/>
                  <div>
                    <h2>{data.name_meyer}</h2>
                  </div>
                </Header>
              
                {data.cv_meyer.map(row => (
                  <CVRow key={row.value.label}>
                    <CVColLabel>{row.value.label}</CVColLabel>
                    <CVColText>{row.value.text}</CVColText>
                  </CVRow>
                ))}

              </Col>
              <Col xs={12} sm={6} id="cv_karlhuber" className="cv">
                <Header>
                  <Image src={`${baseUrl}/${data.image_karlhuber.path}`} alt={data.name_karlhuber} id="image_karlhuber" />
                  <div>
                    <h2>{data.name_karlhuber}</h2>
                  </div>
                </Header>

                {data.cv_karlhuber.map(row => (
                  <CVRow key={row.value.label}>
                    <CVColLabel>{row.value.label}</CVColLabel>
                    <CVColText>{row.value.text}</CVColText>
                  </CVRow>
                ))}

              </Col>
              
            </Row>
          </React.Fragment>
        </ThemeProvider>
      </Page>
    );
  }
}

export default Geschaeftsfuehrer;