import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AutoFitImage from 'react-image-autofit-frame';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';
import pdfIcon from '../assets/img/icon_pdf.png';

const StyledSlider = styled(Slider)`
  height: auto;
  overflow: hidden;

  @media only screen and (min-width: ${props => props.theme.bp.medium}) {
    height: 650px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    height: 760px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.xlarge}) {
    height: 1000px;
  }
`;

const ThumbList = styled.div`
  margin-bottom: ${props => props.theme.layout.margin.default};
  border-bottom: 1px dotted ${props => props.theme.color.greyMedium};
  padding-bottom: ${props => props.theme.layout.margin.default};
  margin-bottom: ${props => props.theme.layout.margin.default};
`;

const Thumb = styled.img`
  display: inline-block;
  margin-right: 5px;
  padding: 5px;

  margin-top: ${props => props.theme.layout.margin.default};
`;

const Teaser = styled.h2`
  font-weight: normal;
`;

const Description = styled.div``;

const Table = styled.table`
  display: table;
`;

const Row = styled.div`
  display: table-row;
`;

const Col = styled.div`
  display: table-cell;

  &:first-child {
    padding-right: 10px;
  }

  p {
    margin-bottom: 0;
  }
`;

const Headline = styled.h2`
  margin: 0;
`;

const StyledAutoFitImage = styled(AutoFitImage)`
  margin: 5px;
`;

const Test = styled.div`
  padding: 10px 10px 0 0;
  /* margin-bottom: ${props => props.theme.layout.margin.default};
  padding-bottom: ${props => props.theme.layout.margin.default};*/
`;

const ThumbSlider = styled(Slider)`
  overflow: hidden;
  height: 90px;
  border-bottom: 1px dotted ${props => props.theme.color.greyMedium};
  margin-bottom: ${props => props.theme.layout.margin.small}; 

  @media only screen and (min-width: ${props => props.theme.bp.medium}) {
    height: 70px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    height: 90px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.xlarge}) {
    height: 110px;
  }
`;

class Project extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      frameHeight: null,
      thumbHeight: null
    };

    this.pageRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      frameHeight: this.pageRef.current.clientWidth / 1.46,
      thumbHeight: '70px'
    });

    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  resizeHandler = () => {
   
    if (window.innerWidth >= 2560) {
      this.setState({
        frameHeight: '1000px',
        thumbHeight: '90px'
      });
    } else if (window.innerWidth >= 1921) {
      this.setState({
        frameHeight: '760px',
        thumbHeight: '70px'
      });
    } else if (window.innerWidth >= 952) {
      this.setState({
        frameHeight: '650px',
        thumbHeight: '50px'
      });
    } else {
      this.setState({
        frameHeight: this.pageRef.current.clientWidth / 1.46,
        thumbHeight: '70px'
      });
    }
  };

  render() {
    let slider = null;
    const project = this.props.data.filter(project => project.title_slug === this.props.match.params.slug)[0];

    const volume = project.volume ? <Row><Col>Volumen:</Col><Col dangerouslySetInnerHTML={{ __html: project.volume }}></Col></Row> : null;
    const costs = project.costs ? <Row><Col>Kosten:</Col><Col dangerouslySetInnerHTML={{ __html: project.costs }}></Col></Row> : null;
    const builder = project.builder ? <Row><Col>Bauherr:</Col><Col dangerouslySetInnerHTML={{ __html: project.builder }}></Col></Row> : null;
    const client = project.client ? <Row><Col>Auftraggeber:</Col><Col dangerouslySetInnerHTML={{ __html: project.client }}></Col></Row> : null;
    const location = project.location ? <Row><Col>Ort:</Col><Col dangerouslySetInnerHTML={{ __html: project.location }}></Col></Row> : null;
    const commissioning = project.commissioning ? <Row><Col>Beauftragung:</Col><Col dangerouslySetInnerHTML={{ __html: project.commissioning }}></Col></Row> : null;
    const period = project.period ? <Row><Col>Zeitraum:</Col><Col dangerouslySetInnerHTML={{ __html: project.period }}></Col></Row> : null;
    const cooperation = project.cooperation ? <Row><Col>Mitarbeiter:</Col><Col dangerouslySetInnerHTML={{ __html: project.cooperation }}></Col></Row> : null;
    const partner = project.partner ? <Row><Col>Partner:</Col><Col dangerouslySetInnerHTML={{ __html: project.partner }}></Col></Row> : null;
    const photos = project.photos ? <Row><Col>Fotos:</Col><Col dangerouslySetInnerHTML={{ __html: project.photos }}></Col></Row> : null;
    const promoter = project.promoter ? <Row><Col>Auslober:</Col><Col dangerouslySetInnerHTML={{ __html: project.promoter }}></Col></Row> : null;
    const publications = project.publications ? <Row><Col>Veröffentlichungen:</Col><Col dangerouslySetInnerHTML={{ __html: project.publications }}></Col></Row> : null;
    const investor = project.investor ? <Row><Col>Investor:</Col><Col dangerouslySetInnerHTML={{ __html: project.investor }}></Col></Row> : null;
    const art = project.art ? <Row><Col>Kunst am Bau:</Col><Col dangerouslySetInnerHTML={{ __html: project.art }}></Col></Row> : null;
    const visualisation = project.visualisation ? <Row><Col>Visualisierung:</Col><Col dangerouslySetInnerHTML={{ __html: project.visualisation }}></Col></Row> : null;
    const organizer = project.organizer ? <Row><Col>Veranstalter:</Col><Col dangerouslySetInnerHTML={{ __html: project.organizer }}></Col></Row> : null;
    const status = project.status ? <Row><Col>Projektstand:</Col><Col dangerouslySetInnerHTML={{ __html: project.status }}></Col></Row> : null;
    const completion = project.completion ? <Row><Col>Fertigstellung:</Col><Col dangerouslySetInnerHTML={{ __html: project.completion }}></Col></Row> : null;
    const landscape_architect = project.landscape_architect ? <Row><Col>Landschaftsarchitekt:</Col><Col dangerouslySetInnerHTML={{ __html: project.landscape_architect }}></Col></Row> : null;
    const professional_planner = project.professional_planner ? <Row><Col>Fachplaner:</Col><Col dangerouslySetInnerHTML={{ __html: project.professional_planner }}></Col></Row> : null;
    const pdf = project.pdf ? <a href={`${baseUrl}/${project.pdf}`} target="_blank" rel="noopener noreferrer"><img src={pdfIcon} alt="PDF Link" /></a> : null;

    let additionalData = null;

    if (project.additional_data) {
      additionalData = project.additional_data.map(data => (
        <Row>
          <Col>{ data.value.label }</Col>
          <Col dangerouslySetInnerHTML={{ __html: data.value.text }}></Col>
        </Row>
      ));
    }

    const slider2Settings = {
      asNavFor: this.state.nav1,
      slidesToShow: 10,
      swipeToSlide: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 952,
          settings: {
            slidesToShow: 6
          }
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 4
          }
        }
      ]
    };

    let daten = null;

    if (
      client || 
      builder || 
      location || 
      commissioning || 
      period || 
      volume || 
      costs || 
      cooperation || 
      partner || 
      promoter || 
      investor || 
      photos || 
      publications || 
      art || 
      visualisation || 
      organizer || 
      status || 
      completion || 
      landscape_architect || 
      professional_planner || 
      additionalData) {
        daten = (
          <React.Fragment>
            <h3>Daten</h3>
            <Table>
              {client}
              {builder}
              {location}
              {commissioning}
              {period}
              {volume}
              {costs}
              {cooperation}
              {partner}
              {promoter}
              {investor}
              {photos}
              {publications}
              {art}
              {visualisation}
              {organizer}
              {status}
              {completion}
              {landscape_architect}
              {professional_planner}
              {additionalData}
            </Table>  
          </React.Fragment>  
        );
      }

    return (
      <Page title={project.title}>
        <div ref={this.pageRef} className="mainSlider">
          <StyledSlider
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
            // autoplay={true}
            speed={500}
            autoplaySpeed={5000}
          >
            {project.gallery.map(image => (
              <div>
                <div style={{ position: 'relative' }}>
                  <AutoFitImage imgSize="contain" positionX="left" positionY="center" frameWidth="100%" frameHeight={this.state.frameHeight} imgSrc={`${baseUrl}/${image.path}`} />
                  {image.meta.title && <p style={{ position: 'absolute', bottom: '0', left: '9px', backgroundColor: 'white', padding: '2px 5px' }}>{image.meta.title}</p>}
                </div>
              </div>
            ))}
          </StyledSlider>
        </div>
          
        <ThumbSlider ref={slider => (this.slider2 = slider)} {...slider2Settings}>
          {project.gallery.map((image, index) => (
            // <Thumb src={`${baseUrl}/${image.path}`} alt="" width="100" />
            <Test style={{ padding: '5px'}}>
              <StyledAutoFitImage imgSize="cover" positionX="center" positionY="center" frameWidth="" frameHeight={this.state.thumbHeight} imgSrc={`${baseUrl}/${image.path}`} style={{ margin: '5px'}} />
            </Test>
          ))}
        </ThumbSlider>
        
        <Headline>{project.title}</Headline>
        <Teaser dangerouslySetInnerHTML={{ __html: project.teaser }}></Teaser>
        <Description dangerouslySetInnerHTML={{ __html: project.description }}></Description>

        {daten}

        {pdf}
        
      </Page>
    );
  }
}

export default Project;