import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import AutoFitImage from 'react-image-autofit-frame';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';
import pdfIcon from '../assets/img/icon_pdf.png';

// STYLES
const StyledSlider = styled.div`
  height: auto;
  overflow: hidden;

  @media only screen and (min-width: ${props => props.theme.bp.medium}) {
    height: 650px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    height: 760px;
  }

  @media only screen and (min-width: ${props => props.theme.bp.xlarge}) {
    height: 1000px;
  }
`;

const Description = styled.div`
  margin-top: 1rem;
`;

const Headline = styled.h2`
  margin: 0;
  margin-top: 0.5rem;
`;


// COMPONENT
class Stellenangebote extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      frameHeight: null,
      thumbHeight: null
    };
    this.pageRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      frameHeight: this.pageRef.current.clientWidth / 1.46,
      thumbHeight: '70px'
    });
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  resizeHandler = () => {
    if (window.innerWidth >= 2560) {
      this.setState({
        frameHeight: '1000px',
        thumbHeight: '90px'
      });
    } else if (window.innerWidth >= 1921) {
      this.setState({
        frameHeight: '760px',
        thumbHeight: '70px'
      });
    } else if (window.innerWidth >= 952) {
      this.setState({
        frameHeight: '650px',
        thumbHeight: '50px'
      });
    } else {
      this.setState({
        frameHeight: this.pageRef.current.clientWidth / 1.46,
        thumbHeight: '70px'
      });
    }
  };

  render() {
   
    const { data } = this.props;

    return (
      <Page title="Stellenangebote" margin>
        <div ref={this.pageRef}>
          <StyledSlider>
            <AutoFitImage imgSize="contain" positionX="left" positionY="center" frameWidth="100%" frameHeight={this.state.frameHeight} imgSrc={`${baseUrl}/${data.image.path}`} />
          </StyledSlider>
        </div>
          
        <Headline>{data.headline}</Headline>
        <Description dangerouslySetInnerHTML={{ __html: data.text }}></Description>
        
      </Page>
    );
  }
}

export default Stellenangebote;