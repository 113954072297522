import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const MediaContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.layout.margin.default};
`;

const MediaImage = styled.img`
  margin-right: ${props => props.theme.layout.margin.default};
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none; 

  &:hover {
    text-decoration: none; 
  }
`;

const MediaHeadline = styled.h3`
  padding: 0;
  margin: 0;
`;

const Media = props => {
  
  const img = props.image ? <MediaImage src={props.image} alt="" /> : <MediaImage src="https://via.placeholder.com/100x150" alt="" />; 

  return (
    <MediaContainer>
      <StyledNavLink to={props.link}>
        {img}
      </StyledNavLink>

      <div>
        <StyledNavLink to={props.link}>
          <MediaHeadline>{props.title}</MediaHeadline>
        </StyledNavLink>
        <div dangerouslySetInnerHTML={{ __html: props.children }}></div>
      </div>
    </MediaContainer>
  );
};

export default Media;