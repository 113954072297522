import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Nav = styled.nav`
  text-align: left;
  border-bottom: 1px dotted ${props => props.theme.color.greyMedium};
  padding-bottom: ${props => props.theme.layout.margin.small};
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  display: inline-block;
  margin-right: 15px;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  text-transform: uppercase;
  color: ${props => props.theme.color.greyMedium};
  

  &:hover,
  &.active {
    text-decoration: inherit;
    color: black;
  }
`;

const Navigation = props => {

  return (
    <Nav {...props}>
      <NavList>
        <NavItem>
          <StyledNavLink to="/neuigkeiten">Aktuelles</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink to="/projekte">Projekte</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink to="/wettbewerbe">Wettbewerbe</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink to="/buero">Büro</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink to="/veroeffentlichungen">Veröffentlichungen</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink to="/kontakt">Kontakt</StyledNavLink>
        </NavItem>
      </NavList>
    </Nav>
  );
}

export default Navigation;