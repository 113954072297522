import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AutoFitImage from 'react-image-autofit-frame';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';
import pdfIcon from '../assets/img/icon_pdf.png';

const StyledSlider = styled(Slider)`
  height: 640px;
  height: 500px;
  overflow: hidden;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    height: 1100px;
  }
`;

const ThumbList = styled.div`
  margin-bottom: ${props => props.theme.layout.margin.default};
  border-bottom: 1px dotted ${props => props.theme.color.greyMedium};
  padding-bottom: ${props => props.theme.layout.margin.default};
  margin-bottom: ${props => props.theme.layout.margin.default};
`;

const Thumb = styled.img`
  display: inline-block;
  margin-right: 5px;
  height: 50px;
  width: auto;
  margin-top: ${props => props.theme.layout.margin.default};
`;

const Teaser = styled.h2`
  font-weight: normal;
`;

const Description = styled.div``;

const Table = styled.table`
  display: table;
`;

const Row = styled.div`
  display: table-row;
`;

const Col = styled.div`
  display: table-cell;

  &:first-child {
    padding-right: 10px;
  }

  p {
    margin-bottom: 0;
  }
`;

const Headline = styled.h2`
  margin: 0;
`;

const Release = props => {
  
  let slider = null;
  let frameHeight = '500px';
  const release = props.data.filter(release => release.title_slug === props.match.params.slug)[0];

  const resizeHandler = () => {
    if (window.innerWidth > 1921) {
      frameHeight = '1155px';
    }
  };

  window.addEventListener('resize', resizeHandler);
  resizeHandler();

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000
  };

  const clickHandler = index => {
    slider.slickGoTo(index);
  }

  let gallery = null;
  
  if (release.gallery) {
    gallery = (
      <React.Fragment>
        <StyledSlider ref={c => (slider = c)} {...sliderSettings}>
          {release.gallery.map(image => (
            <AutoFitImage imgSize="contain" positionX="left" positionY="center" frameWidth="100%" frameHeight={frameHeight} imgSrc={`${baseUrl}/${image.path}`} />
          ))}
        </StyledSlider>

        <ThumbList>
          {release.gallery.map((image, index) => (
            <Thumb src={`${baseUrl}/${image.path}`} alt="" width="100" onClick={ () => { clickHandler(index) } } />
          ))}
        </ThumbList>
      </React.Fragment>
    );
  }

  const pdf = release.pdf ? <a href={`${baseUrl}/${release.pdf}`} target="_blank" rel="noopener noreferrer"><img src={pdfIcon} alt="PDF Link" /></a> : null;

  return (
    <Page title={release.title} margin>
      {gallery}

      <Headline>{release.title}</Headline>
      <Description dangerouslySetInnerHTML={{ __html: release.text }}></Description>

      {pdf}
    </Page>
  );
}

export default Release;