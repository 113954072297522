import React from 'react';
import Page from '../components/Page';

const Pressekontakt = props => {

  return (
    <Page title="Pressekontakt" margin>
      <h2>{props.data.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: props.data.text }}></div>
    </Page>
  );
}

export default Pressekontakt;