import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Route, Link, Redirect, withRouter, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './App.css';
import cockpit from './utils/cockpit';
import Layout from './components/Layout';
import Header from './components/Header';
import Projects from './pages/Projects';
import Contests from './pages/Contests';
import Project from './pages/Project';
import Office from './pages/Office';
import Partners from './pages/Partners';
import Employees from './pages/Employees';
import Jobs from './pages/Jobs';
import Releases from './pages/Releases';
import Press from './pages/Press';
import Publications from './pages/Publications';
import Exhibitions from './pages/Exhibitions';
import Contact from './pages/Contact';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import Release from './pages/Release';
import Detail from './pages/Detail';
import Stellenangebote from './pages/Stellenangebote';
import Geschaeftsfuehrer from './pages/Geschaeftsfuehrer';
import Neuigkeiten from './pages/News';
import Pressekontakt from './pages/Pressekontakt';

class App extends React.Component {

  state = {};

  componentDidMount() {
    cockpit.getReleases().then(res => {this.setState({releases: res.data.entries.filter(release => release.published === true)})});
    cockpit.getSingleton('projects', true).then(res => {this.setState({projectsProjects: res.data})});
    cockpit.getSingleton('contests', true).then(res => {this.setState({contestsProjects: res.data})});
    cockpit.getSingleton('contact').then(res => {this.setState({contact: res.data})});
    cockpit.getSingleton('imprint').then(res => {this.setState({imprint: res.data})});
    cockpit.getSingleton('privacy').then(res => {this.setState({privacy: res.data})});
    cockpit.getSingleton('jobs').then(res => {this.setState({stellenangebote: res.data})});
    cockpit.getSingleton('directors').then(res => {this.setState({geschaeftsfuehrer: res.data})});
    cockpit.getSingleton('haltung').then(res => {this.setState({haltung: res.data})});
    cockpit.getSingleton('pressekontakt').then(res => {this.setState({pressekontakt: res.data})});
    cockpit.getCollection('team').then(res => {this.setState({team: res.data.entries })});
    cockpit.getCollection('press').then(res => {this.setState({press: res.data.entries.filter(item => item.published).reverse()})});
    cockpit.getCollection('publications').then(res => {this.setState({publications: res.data.entries})});
    cockpit.getCollection('neuigkeiten').then(res => {this.setState({news: res.data.entries})});
  }

  render() {
    const { 
      projectsProjects, 
      contestsProjects, 
      releases, 
      publications, 
      press, 
      contact, 
      imprint, 
      privacy,
      team,
      stellenangebote,
      geschaeftsfuehrer,
      haltung,
      pressekontakt,
      news
    } = this.state;

    let result = null;

    if (
      projectsProjects && 
      contestsProjects && 
      releases && 
      publications && 
      press && 
      contact && 
      imprint && 
      privacy &&
      team &&
      stellenangebote &&
      geschaeftsfuehrer &&
      haltung && 
      news &&
      pressekontakt
    ) {
      result = (
        <React.Fragment>
          <Header />
          <main>
            <TransitionGroup>
              <CSSTransition key={this.props.location.key} classNames="fade" timeout={{enter: 300, exit: 300}}>
                <Switch location={this.props.location}>
                  <Route path="/" exact render={ props => <Projects {...props} data={projectsProjects.projects} /> } />
                  <Route path="/projekte/:slug" render={ props => <Project {...props} data={projectsProjects.projects} /> } />
                  <Route path="/projekte" render={ props => <Projects {...props} data={projectsProjects.projects} /> } />
                  <Route path="/wettbewerbe/:slug" render={ props => <Project {...props} data={contestsProjects.projects} /> } />
                  <Route path="/wettbewerbe" render={ props => <Contests {...props} data={contestsProjects.projects} /> } />
                  <Route path="/neuigkeiten" render={ props => <Neuigkeiten {...props} data={news} /> } />
                  <Route path="/buero/bueropartner" render={ props => <Geschaeftsfuehrer {...props} data={geschaeftsfuehrer} /> } />
                  <Route path="/buero/team" render={ props => <Employees {...props} team={team} /> } />
                  <Route path="/buero/ueber-uns" render={ props => <Imprint {...props} data={haltung} /> } />
                  <Route path="/buero/stellenangebote" render={ props => <Stellenangebote {...props} data={stellenangebote} /> } />
                  <Route path="/buero" render={ () => <Redirect to="/buero/team" /> } />   
                  <Route path="/veroeffentlichungen/presse/:slug" render={ props => <Detail {...props} data={press} /> } />
                  <Route path="/veroeffentlichungen/presse" render={ props => <Press {...props} data={press} press={press} /> } />
                  <Route path="/veroeffentlichungen/publikationen/:slug" render={ props => <Detail {...props} data={publications} /> } /> 
                  <Route path="/veroeffentlichungen/publikationen" render={ props => <Publications {...props} data={publications} /> } />
                  <Route path="/veroeffentlichungen/pressekontakt" render={ props => <Pressekontakt {...props} data={pressekontakt} /> } />
                  <Route path="/veroeffentlichungen" render={() => <Redirect to="/veroeffentlichungen/publikationen"/>}/>
                  <Route path="/kontakt/datenschutz" render={ props => <Privacy {...props} data={privacy} /> } /> 
                  <Route path="/kontakt/impressum" render={ props => <Imprint {...props} data={imprint} /> } /> 
                  <Route path="/kontakt" render={ props => <Contact {...props} data={contact} /> } />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </main>
        </React.Fragment>
      );
    }

    return (
      <Layout>{result}</Layout>
    );
  }
}

export default withRouter(App);
