import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Nav = styled.nav`
  margin: ${props => props.theme.layout.margin.large} 0 ${props => props.theme.layout.margin.small} 0;
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  display: inline-block;
  margin-right: 15px;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  text-transform: uppercase;
  color: ${props => props.theme.color.greyMedium};
  

  &:hover,
  &.active {
    text-decoration: inherit;
    color: black;
  }
`;

const FooterNavigation = props => {

  return (
    <Nav>
      <NavList>
        <NavItem>
          <StyledNavLink to="/kontakt/impressum">Impressum</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink to="/kontakt/datenschutz">Datenschutz</StyledNavLink>
        </NavItem>
      </NavList>
    </Nav>
  );
}

export default FooterNavigation;