import axios from './axios';

const getProjects = () => axios.get(`collections/get/projects`);

const getReleases = () => axios.get(`collections/get/publications`);

const getSingleton = (singleton, extended = false) => {
  if (extended) {
    return axios.get(`singletons/get/${singleton}?populate=1`); 
  }

  return axios.get(`singletons/get/${singleton}`);
}

const getCollection = collection => axios.get(`collections/get/${collection}`);
  
export default {
  getProjects: getProjects,
  getReleases: getReleases,
  getSingleton: getSingleton,
  getCollection: getCollection
}